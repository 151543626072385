import React from 'react'
import './../Styles/FirmaStyle.css'

const Firma = () => {
  return (
    <div id="Firma">
      <p style={{ textAlign: 'center' }}>
        Sitio diseñado 100% por Gabriel Bellome
      </p>
    </div>
  )
}

export default Firma
