import React from 'react'

const Maps = () => {
  return (
    <iframe 
    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13138.59641428686!2d-58.577998507763674!3d-34.58774430720785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sar!4v1670291591094!5m2!1ses-419!2sar"
    allowfullscreen="" 
    loading="lazy"
    style={{'border': '0px'}}
    referrerpolicy="no-referrer-when-downgrade"
    title='Maps3Febrero'>
    </iframe>
  )
}

export default Maps




